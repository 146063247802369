// COMPRESSION DRAGGING STARTS

let startingX;
let thumbRight = 0;
let compressionSliderRect;
let leftSliderBound;
let rightSliderBound;
let sliderWidth;

const sliderProgress = document.querySelector(
    ".sliderProgress"
);

const compressionSlider = document.querySelector(
    ".compressionSlider"
);

window.addEventListener("load", function () {
    initPercentages(70);
});

window.addEventListener("resize", function () {
    initPercentages(compressionPercentage.value);
});

function initPercentages(percentage) {
    compressionSliderRect =
        compressionSlider.getBoundingClientRect();

    leftSliderBound = compressionSliderRect.x;
    sliderWidth = compressionSliderRect.width;
    rightSliderBound =
        compressionSliderRect.x + sliderWidth;

    compressionPercentage.value = percentage;
    percentageInputChangeHandler(percentage);
}

const sliderThumb = document.querySelector(".sliderThumb");

sliderThumb.addEventListener("mousedown", dragStart);

function dragStart(e) {
    e.preventDefault();

    startingX = e.clientX;

    document.addEventListener("mousemove", dragMove);
    document.addEventListener("mouseup", dragEnd);
}

function dragMove(e) {
    if (e.clientX > rightSliderBound) {
        console.log("too far right");
        sliderRight(0);
        updatePercentage(e, 100);
        return;
    }

    if (e.clientX < leftSliderBound) {
        console.log("too far left");
        sliderRight(compressionSliderRect.width);
        updatePercentage(e, 0);
        return;
    }

    sliderRight(thumbRight + (startingX - e.clientX));

    updatePercentage(e, null);
}

function dragEnd(e) {
    e.preventDefault();

    thumbRight = Number(
        sliderThumb.style.right.split("px")[0]
    );

    document.removeEventListener("mousemove", dragMove);
    document.removeEventListener("mouseup", dragEnd);
}

function sliderRight(pixels) {
    sliderThumb.style.right = `${pixels}px`;
}

function updatePercentage(e, num) {
    const level = e.clientX - leftSliderBound;

    if (num === null) {
        const percentage = (level / sliderWidth) * 100;
        compressionPercentage.value =
            Math.floor(percentage);
        sliderProgress.style.width = `${level}px`;
    } else {
        compressionPercentage.value = Math.floor(num);
    }
}

// COMPRESSION DRAGGING ENDS

compressionPercentage.addEventListener(
    "input",
    compressionPercentageHandler
);
function compressionPercentageHandler(e) {
    if (Number(this.value) > 100) {
        this.value = this.value.slice(
            0,
            this.value.length - 1
        );
        return;
    }

    percentageInputChangeHandler(Number(this.value));
}

function percentageInputChangeHandler(percentageInput) {
    const level = sliderWidth * (percentageInput / 100);
    sliderProgress.style.width = `${level}px`;

    const rightDelta = sliderWidth - level;

    sliderRight(rightDelta);
    thumbRight = rightDelta;
}
