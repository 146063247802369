// INTERSECTION STARTS

const fileUploadZone = document.querySelector(
    ".fileUploadZone"
);

// const observer = new IntersectionObserver((entries) => {
//     entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//             const script = document.createElement("script");
//             script.src = "/js/wasm-init.js";
//             document.head.appendChild(script);
//             observer.unobserve(entry.target);
//         }
//     });
// });

// observer.observe(fileUploadZone);

// INTERSECTION ENDS

const compressionPercentage = document.querySelector(
    ".compressionPercentage"
);

const outputImgPlaceholder = document.querySelector(
    ".outputImgPlaceholder"
);

let imgFile;

let sizeSetting = "1000";
let imgEffect = "";

const sizeBtns = document.querySelectorAll(".sizeBtn");

for (const sizeBtn of sizeBtns) {
    sizeBtn.addEventListener("click", sizeBtnHandler);
}
function sizeBtnHandler() {
    for (const btn of sizeBtns) {
        if (btn === this) {
            btn.classList.add("selectedSizeBtn");
        } else {
            btn.classList.remove("selectedSizeBtn");
        }
    }

    sizeSetting = this.dataset.size;
}

const selectedOutputBtnContainer = document.querySelector(
    ".selectedOutputBtnContainer"
);

const sizeSelectBtn = document.querySelector(
    ".sizeSelectBtn"
);

const selectOutputContainer = document.querySelector(
    ".selectOutputContainer"
);
selectOutputContainer.addEventListener(
    "click",
    selectOutputContainerHandler
);
function selectOutputContainerHandler() {
    sizeSelectBtn.classList.toggle("openSelect");
}

const changeOutputBtns = document.querySelectorAll(
    ".changeOutputBtn"
);

for (const changeOutputBtn of changeOutputBtns) {
    changeOutputBtn.addEventListener(
        "click",
        changeOutputBtnHandler
    );
}
function changeOutputBtnHandler() {
    imgEffect = this.dataset.effect;
    selectedOutputBtnContainer.innerHTML = this.outerHTML;
    sizeSelectBtn.classList.toggle("openSelect");
}

const imgOutputPreview = document.querySelector(
    "#imgOutputPreview"
);

const imgUploadInput = document.querySelector(
    "#imgUploadInput"
);

const downloadCompressedImgBtn = document.querySelector(
    "#downloadCompressedImgBtn"
);

const imgOutputCard = document.querySelector(
    ".imgOutputCard"
);

const outputLoadingSpinner = document.querySelector(
    ".outputLoadingSpinner"
);

imgUploadInput.addEventListener("change", () => {
    compressImgHandler(imgUploadInput.files[0]);
});

async function compressImgHandler(imgFileUpload) {
    if (!imgFileUpload || imgFileUpload.value === "") {
        return;
    }

    outputImgPlaceholder.classList.add("hidden");
    imgOutputPreview.classList.add("hidden");
    outputLoadingSpinner.classList.remove("hidden");

    imgFile = imgFileUpload;
    const encodedFile = await fileToUint8Array(imgFile);

    readFileHandler(encodedFile);
}

fileUploadZone.addEventListener(
    "click",
    fileUploadZoneClickHandler
);
function fileUploadZoneClickHandler() {
    imgUploadInput.click();
}

function imgDragOverHandler(e) {
    e.preventDefault();
    fileUploadZone.classList.add("hoverUploadZone");
}

function imgDragLeaveHandler(e) {
    e.preventDefault();
    fileUploadZone.classList.remove("hoverUploadZone");
}

function imgDropHandler(e) {
    e.preventDefault();

    fileUploadZone.classList.remove("hoverUploadZone");

    if (
        !e.dataTransfer.items ||
        e.dataTransfer.items[0].kind !== "file"
    ) {
        console.log("no file added");
        return;
    }

    compressImgHandler(e.dataTransfer.items[0].getAsFile());
}

async function readFileHandler(encodedFile) {
    if (
        window.matchMedia("(orientation: portrait)")
            .matches ||
        window.matchMedia("(max-width: 900px)").matches
    ) {
        imgOutputCard.scrollIntoView();
    }

    const imgNameArr = imgFile.name.split(".");
    imgNameArr.pop();
    const imgName = imgNameArr.join("");
    const imgType = imgFile.type.split("/")[1];

    const originalKbSize = imgFile.size / 1000;

    const originalSizeTxt = document.querySelector(
        ".originalSizeTxt"
    );

    let txtOriginalSize;

    if (originalKbSize > 1000) {
        txtOriginalSize = `${(
            originalKbSize / 1000
        ).toFixed(2)}MB`;
    } else {
        txtOriginalSize = `${Math.round(originalKbSize)}KB`;
    }

    originalSizeTxt.textContent = txtOriginalSize;

    let compressionQualityStr = Math.max(
        Number(compressionPercentage.value),
        1
    ).toString();

    // const compressedBytes = await compressJpg(
    //     new Uint8Array(initialBuffer),
    //     imgWidth,
    //     imgHeight
    // );

    let compressedImg = await compress(
        encodedFile.buffer,
        encodedFile.array,
        imgType.toUpperCase(),
        sizeSetting,
        imgEffect,
        compressionQualityStr
    );

    let sizeInKb = compressedImg.byteLength / 1024;

    const imgBlob = new Blob([compressedImg], {
        type: "image/jpeg",
    });
    const imgUrl = URL.createObjectURL(imgBlob);

    outputLoadingSpinner.classList.add("hidden");
    imgOutputPreview.setAttribute("src", imgUrl);
    imgOutputPreview.classList.remove("hidden");

    downloadCompressedImgBtn.setAttribute("href", imgUrl);

    downloadCompressedImgBtn.setAttribute(
        "download",
        `${imgName}.${imgType}`
    );

    downloadCompressedImgBtn.classList.remove("disabled");

    const newSizeTxt =
        document.querySelector(".newSizeTxt");

    if (sizeInKb > 1024) {
        newSizeTxt.textContent = `${(
            sizeInKb / 1024
        ).toFixed(2)}MB`;
    } else {
        newSizeTxt.textContent = `${Math.round(
            sizeInKb
        )}KB`;
    }

    const savingsTxt =
        document.querySelector(".savingsTxt");

    savingsTxt.textContent = `${(
        (1 - sizeInKb / originalKbSize) *
        100
    ).toFixed(2)}%`;
}

function fileToUint8Array(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const buffer = reader.result;
            const array = new Uint8Array(buffer);
            resolve({
                buffer,
                array,
            });
        };
        reader.onerror = () => {
            reject(reader.error);
        };
        reader.readAsArrayBuffer(file);
    });
}
