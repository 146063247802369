function fallingSvgs() {
    const svgContainers =
        document.querySelectorAll(".svgContainer");
    for (const svgContainer of svgContainers)
        setRandomFallingAnimation(svgContainer);
    function setRandomFallingAnimation(n) {
        n.classList.add(`falling${randomNumber(5)}`);
    }
    function randomNumber(n) {
        return Math.round(Math.random() * n);
    }
}

fallingSvgs();

const sampleAppSection = document.querySelector(
    ".sampleAppSection"
);

const seeMyWorkBtn =
    document.querySelector(".seeMyWorkBtn");
seeMyWorkBtn.addEventListener("click", seeMyWorkBtnHandler);
function seeMyWorkBtnHandler() {
    sampleAppSection.scrollIntoView();
}

const emailTxts = document.querySelectorAll(".emailTxt");
const email = emailTxts[0].textContent;

const getInTouchBtns = document.querySelectorAll(
    ".getInTouchBtn"
);

for (const getInTouchBtn of getInTouchBtns) {
    getInTouchBtn.addEventListener(
        "click",
        getInTouchBtnHandler
    );
}

function getInTouchBtnHandler() {
    toggle(
        this.closest(
            ".getInTouchBtnContainer"
        ).querySelector(".getInTouchMsg")
    );
}

const clipboardEmptySvgContainers =
    document.querySelectorAll(
        ".clipboardEmptySvgContainer"
    );

for (const clipboardEmptySvgContainer of clipboardEmptySvgContainers) {
    clipboardEmptySvgContainer.addEventListener(
        "click",
        copyEmailToClipboard
    );
}

for (const emailTxt of emailTxts) {
    emailTxt.addEventListener(
        "click",
        copyEmailToClipboard
    );
}

function copyEmailToClipboard() {
    const thisClipboardSvgContainer = this.closest(
        ".getInTouchBtnContainer"
    ).querySelector(".getInTouchMsg");

    thisClipboardSvgContainer.classList.add(
        "clipboardFull"
    );
    navigator.clipboard.writeText(email);

    setTimeout(function () {
        thisClipboardSvgContainer.classList.remove(
            "clipboardFull"
        );
    }, 3000);
}

function show(el) {
    el.classList.remove("hidden");
}

function hide(el) {
    el.classList.add("hidden");
}

function toggle(el) {
    el.classList.toggle("hidden");
}
