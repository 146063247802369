const worker = new Worker("./js/wasm-worker1.js", {
    type: "module",
});

let startTime;

function startTimer() {
    startTime = new Date();
}

function endTimer() {
    const endTime = new Date();
    const timeDiff = endTime - startTime;
    console.log(`Timer ran for ${timeDiff} milliseconds`);
}

async function compress(
    buffer,
    array,
    fileType,
    size,
    effect,
    quality
) {
    startTimer();
    const { imgWidth, imgHeight } =
        await getImageDimensions(buffer);
    endTimer();

    startTimer();
    const { newBytes } = await jpgResize(
        array,
        imgWidth,
        imgHeight,
        Number(size)
    );
    endTimer();

    worker.postMessage({
        newBytes,
        effect,
        quality,
    });

    const result = await new Promise((resolve) => {
        worker.onmessage = (event) => {
            resolve(event.data.result);
        };
    });

    return result;
}

async function jpgResize(
    bytes,
    originalWidth,
    originalHeight,
    targetWidth
) {
    let newWidth = originalWidth;
    let newHeight = originalHeight;

    if (originalWidth > targetWidth) {
        newWidth = targetWidth;
        newHeight = Math.round(
            (originalHeight * newWidth) / originalWidth
        );
    }

    const blob = new Blob([bytes], {
        type: `image/jpeg`,
    });
    const imgBitmap = await createImageBitmap(blob, {
        resizeWidth: newWidth,
        resizeHeight: newHeight,
    });

    const canvas = new OffscreenCanvas(newWidth, newHeight);
    const ctx = canvas.getContext("2d");

    ctx.drawImage(imgBitmap, 0, 0);
    imgBitmap.close();

    const resizedBlob = await canvas.convertToBlob({
        type: "image/jpeg",
    });
    const resizedArrayBuffer =
        await resizedBlob.arrayBuffer();
    const resizedBytes = new Uint8Array(resizedArrayBuffer);

    return {
        width: newWidth,
        height: newHeight,
        newBytes: resizedBytes,
    };
}

async function getImageDimensions(arrayBuffer) {
    const dataView = new DataView(arrayBuffer);
    let imgWidth, imgHeight;

    if (dataView.getUint16(0) === 0xffd8) {
        let offset = 2;
        while (offset < dataView.byteLength) {
            const marker = dataView.getUint16(offset);
            offset += 2;

            if (
                marker === 0xffc0 ||
                marker === 0xffc1 ||
                marker === 0xffc2 ||
                marker === 0xffc3
            ) {
                imgHeight = dataView.getUint16(offset + 3);
                imgWidth = dataView.getUint16(offset + 5);
                break;
            } else {
                // Move to the next marker
                offset += dataView.getUint16(offset);
            }
        }
    }
    // Check for PNG (starts with 0x89504E47)
    else if (dataView.getUint32(0) === 0x89504e47) {
        imgWidth = dataView.getUint32(16);
        imgHeight = dataView.getUint32(20);
    } else {
        try {
            const blob = new Blob([arrayBuffer], {
                type: "image/webp",
            });
            const img = await createImageBitmap(blob);
            imgWidth = img.width;
            imgHeight = img.height;
            img.close();
        } catch (e) {
            throw new Error("Unsupported image format");
        }
    }

    return { imgWidth, imgHeight };
}
